import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subscription } from 'rxjs';
import ScheduleCalendarService from '../schedule-calendar.service';
import ScheduleApiService, {
  MonthlyCalendarDay, MonthlyCalendarItem,
  MonthlyCalendarResponse,
} from '../../schedule-api.service';
import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';
import LanguageService from '@shared/language/language.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ScheduleDayCalendarComponent } from '../schedule-day-calendar/schedule-day-calendar.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from '../../../auth/auth.service';
import DealService from '../../../deal/deal.service';
import {
  dateToYyyyMmDd,
  daysBetweenDates,
  isPastTime,
  timeSlotDiapasonToTimeString,
  timeStringToTimeSlotDiapason
} from '@shared/utils';
import FreeSlotsService from '../../../free-slots/free-slots.service';
import { LessonStatus, LessonType } from '@shared/services/lessons/dto/lesson.dto';
import { DateTime } from 'luxon';

@Component({
  selector: 'mathema-schedule-month-calendar',
  templateUrl: './schedule-month-calendar.component.html',
  styleUrls: ['./schedule-month-calendar.component.scss']
})
export class ScheduleMonthCalendarComponent implements OnInit {

  public selectedDayDate: string;
  public mobileMode: boolean = false;
  public weekDayIndexes: number[] = [1, 2, 3, 4, 5, 6, 0];
  public calendarView$: Observable<MonthlyCalendarResponse>;
  public loadingError$: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);

  @Input() dealMode: boolean;
  private activeDealDaysSetupped;

  @Input() freeSlotsMode: boolean;

  private readonly subscriptions: Subscription[] = [];

  constructor(
    public readonly authService: AuthService,
    public readonly scheduleCalendarService: ScheduleCalendarService,
    public readonly dealService: DealService,
    public readonly freeSlotsService: FreeSlotsService,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly languageService: LanguageService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly drawerService: NzDrawerService,
    private readonly modalService: NzModalService,
  ) {
    this.subscriptions.push(this.breakpointObserver.observe(['(max-width: 575px)']).subscribe(result => {
      this.mobileMode = result.matches;
    }));
  }

  ngOnInit(): void {
    const state = combineLatest([
      this.scheduleCalendarService.calendarDate,
      this.scheduleCalendarService.calendarFilters
        .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))),
    ]);

    const calendarViewSub = state.subscribe(([calendarDate, calendarFilters]) => {
      this.loadingError$.next(false);

      const year = calendarDate.getFullYear();
      const month = calendarDate.getMonth();
      this.calendarView$ = this.scheduleApiService.getMonthlyCalendar({
        year,
        month,
        ...calendarFilters,
      }).pipe(
        tap((result) => {
          if (this.dealMode) {
            this.setupDealCalendarDays(result);
            return EMPTY;
          } else if (this.freeSlotsMode) {
            this.setupFreeSlotsDays(result);
            return EMPTY;
          }
          return result;
        }),
        catchError(() => {
          this.loadingError$.next(true);
          return EMPTY;
        }),
      );
    });

    this.subscriptions.push(calendarViewSub);
  }

  public isDayEmpty(day: MonthlyCalendarDay): boolean {
    if (this.dealMode) {
      return !this.dealService.dealCalendarDays.value[day?.['date']]?.items?.length;
    }

    if (this.freeSlotsMode) {
      return !this.freeSlotsService.freeSlotsCalendarDays.value[day.date]?.items?.length;
    }

    return !day['items']?.length;
  }

  public toggleSelectedDay(date: string): void {
    if (this.dealMode || this.freeSlotsMode) {
      return;
    }

    if (this.selectedDayDate === date) {
      this.selectedDayDate = '';
    } else {
      this.selectedDayDate = date;
      this.mobileMode ? this.openDayInModal(date) : this.openDayInDrawer(date);
    }
  }

  private openDayInModal(date: string): void {
    const modalRef = this.modalService.create({
      nzClosable: false,
      nzFooter: null,
      nzStyle: { top: 0 },
      nzWrapClassName: 'day-calendar-modal',
      nzContent: ScheduleDayCalendarComponent,
      nzData: {
        calendarDate: new Date(date),
      }
    });

    this.subscriptions.push(modalRef.afterClose.subscribe(() => {
      this.selectedDayDate = '';
    }));
  }

  private openDayInDrawer(date: string): void {
    const drawerRef = this.drawerService.create({
      nzWidth: '365px',
      nzClosable: false,
      nzWrapClassName: 'day-calendar-drawer',
      nzContent: ScheduleDayCalendarComponent,
      nzData: {
        calendarDate: new Date(date),
      }
    });

    this.subscriptions.push(drawerRef.afterClose.subscribe(() => {
      this.selectedDayDate = '';
    }));
  }

  public formatWeekDayByIdx(idx: number): string {
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      weekday: 'long',
    });

    const date = new Date();
    const currenDayIdx = date.getDay();
    const distance = idx - currenDayIdx;
    date.setDate(date.getDate() + distance);

    return formatter.format(date);
  }

  public formatMobileMonthDay(date: string): string {
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
    });

    const monthDay = new Date(date);

    return formatter.format(monthDay);
  }

  public sortObjectNumericKeys(a, b): number {
    return a.key - b.key;
  }

  public getEmptyArray(size: number): void[] {
    return new Array(size).fill(undefined);
  }

  public isDateYesterdayOrBefore(date: string): boolean {
    return daysBetweenDates(dateToYyyyMmDd(new Date()), date) > 0;
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  // Free slots methods

  public onOpenAddNewFreeSlot(date: string): void {
    this.freeSlotsService.activeDateForNewSlot.next(new Date(date));
  }

  private setupFreeSlotsDays(monthlyCalendar: MonthlyCalendarResponse): void {
    const addedDays = [];
    const newFreeSlotsDays = JSON.parse(JSON.stringify(this.freeSlotsService.freeSlotsCalendarDays.value));

    for (const day in monthlyCalendar) {
      const date = monthlyCalendar[day].date;
      const dateAlreadyStored = Boolean(this.freeSlotsService.freeSlotsCalendarDays.value[date]);

      if (date && !dateAlreadyStored) {
        newFreeSlotsDays[date] = monthlyCalendar[day];
        addedDays.push(monthlyCalendar[day]);
      } else if (date && dateAlreadyStored) {
        const idsWithTimesToRemove = this.freeSlotsService.freeSlotsIdsWithTimesToRemove.value;

        newFreeSlotsDays[date].items = [
          ...newFreeSlotsDays[date].items,
          ...monthlyCalendar[day].items
            .filter((item) => !idsWithTimesToRemove.includes(`${item.id}|${item.time}`))
            .filter((item) => {
              if (item.isLesson) {
                return true;
              }

              if (!item.isLesson) {
                const itemStartSlot = timeStringToTimeSlotDiapason(item.time)[0];

                for (const dayItem of newFreeSlotsDays[date].items) {
                  const dayItemDiapason = timeStringToTimeSlotDiapason(dayItem.time);
                  if (dayItemDiapason.includes(itemStartSlot)) {
                    return false;
                  }
                }
              }

              return true;
            })
            .filter((item) => !newFreeSlotsDays[date].items.map(el => el.id).includes(item.id)),
        ]
          .filter((dayItem: MonthlyCalendarItem) => {
            if (
              !dayItem.isMarkedForFreeSlot &&
              !dayItem.isUIGeneratedSlot &&
              !monthlyCalendar[day].items.map(el => el.id).includes(dayItem.id)
            ) {
              return false;
            }

            return true;
          })
          .sort((itemA, itemB) => itemA.time.localeCompare(itemB.time));
      }
    }

    this.freeSlotsService.freeSlotsCalendarDays.next(newFreeSlotsDays);
    this.freeSlotsService.newSlotsCalendarDays.next(addedDays);
  }

  // Deal methods

  private setupDealCalendarDays(monthlyCalendar: MonthlyCalendarResponse): void {
    const addedDays = [];
    const newDealDays = JSON.parse(JSON.stringify(this.dealService.dealCalendarDays.value));
    const activeDealId = this.dealService.activeDealId.value;

    for (const day in monthlyCalendar) {
      const date = monthlyCalendar[day].date;
      const dateAlreadyStored = Boolean(this.dealService.dealCalendarDays.value[date]);

      if (date && !dateAlreadyStored) {
        newDealDays[date] = monthlyCalendar[day];
        addedDays.push(monthlyCalendar[day]);
      } else if (date && dateAlreadyStored) {
        const reservationsToRemove = this.dealService.reservationsIdsToRemove.value;

        newDealDays[date].items = [
          ...newDealDays[date].items,
          ...monthlyCalendar[day].items
            .filter((item) => !reservationsToRemove.includes(item.id))
            .filter((item) => {
              if (item.isLesson) {
                return true;
              }

              if (!item.isLesson) {
                const itemStartSlot = timeStringToTimeSlotDiapason(item.time)[0];

                for (const dayItem of newDealDays[date].items) {
                  const dayItemDiapason = timeStringToTimeSlotDiapason(dayItem.time);
                  if (dayItemDiapason.includes(itemStartSlot)) {
                    return false;
                  }
                }
              }

              return true;
            })
            .filter((item) => !newDealDays[date].items.map(el => el.id).includes(item.id)),
        ]
          .filter((dayItem: MonthlyCalendarItem) => {
            if (
              !dayItem.isConfirmedReservation &&
              !dayItem.isMarkedForReservation &&
              !dayItem.isUIGeneratedSlot &&
              !monthlyCalendar[day].items.map(el => el.id).includes(dayItem.id)
            ) {
              return false;
            }

            return true;
          })
          .sort((itemA, itemB) => itemA.time.localeCompare(itemB.time));
      }

      if (activeDealId && newDealDays[date]?.items) {
        newDealDays[date].items = newDealDays[date].items.map((item) => this.activeDealItemMapper(item, activeDealId));
        newDealDays[date].items = this.mergeDealDayFreeSlots(newDealDays[date].items);
      }
    }

    this.dealService.dealCalendarDays.next(newDealDays);
    this.dealService.newDealCalendarDays.next(addedDays);

    if (activeDealId && !this.activeDealDaysSetupped) {
      const dealCalendarDays = JSON.parse(JSON.stringify(this.dealService.dealCalendarDays.value));
      const query = this.scheduleCalendarService.calendarFilters.value;
      const subscription = this.scheduleApiService.getSetOfDatesForDeal(query, activeDealId)
        .subscribe(result => {
          for (const day of result) {
            if (!dealCalendarDays[day.date]) {
              dealCalendarDays[day.date] = day;
              dealCalendarDays[day.date].items = day.items.map((item) => this.activeDealItemMapper(item, activeDealId));
              dealCalendarDays[day.date].items = this.mergeDealDayFreeSlots(dealCalendarDays[day.date].items);
            }
          }
          this.dealService.dealCalendarDays.next(dealCalendarDays);
          this.activeDealDaysSetupped = true;
        });
      this.subscriptions.push(subscription);
    }
  }

  private activeDealItemMapper(item: MonthlyCalendarItem, activeDealId: string): MonthlyCalendarItem {
    if (
      item.dealId === activeDealId
      && item.type === LessonType.REGULAR
      && ([LessonStatus.RESERVED, LessonStatus.BOOKED].includes(item.status as LessonStatus))
      && !item.isConducted
    ) {
      item = {
        ...item,
        isLesson: false,
        isConfirmedReservation: true,
        isLessonReservation: true,
        isLessonPaid: item.status === LessonStatus.BOOKED,
        status: undefined,
        type: undefined,
      };
    }

    return item;
  }

  private mergeDealDayFreeSlots(items: MonthlyCalendarItem[]): MonthlyCalendarItem[] {
    const result = [...items.filter((item) =>
      item.isLesson || item.isLessonReservation || item.isConfirmedReservation || item.isMarkedForReservation
    )];
    const freeSlotsDiapason = items
      .filter((item) =>
        !item.isLesson && !item.isLessonReservation && !item.isConfirmedReservation && !item.isMarkedForReservation
      )
      .reduce((acc, item) => acc.concat(timeStringToTimeSlotDiapason(item.time)), []);

    const diapasons: number[][] = [];
    let tmp = [];

    for (let i = 0; i < freeSlotsDiapason.length; i++) {
      if (freeSlotsDiapason[i] + 1 === freeSlotsDiapason[i + 1]) {
        tmp.push(freeSlotsDiapason[i]);
      } else {
        tmp.push(freeSlotsDiapason[i]);
        diapasons.push([...tmp]);
        tmp = [];
      }
    }

    for (const diapason of diapasons) {
      const time = timeSlotDiapasonToTimeString(diapason);
      const sameTimeItem = items.find((item) => item.time === time);

      if (sameTimeItem) {
        result.push({ ...sameTimeItem });
      } else {
        result.push({
          id: crypto.randomUUID(),
          date: items[0].date,
          time,
        });
      }
    }

    result.sort((itemA, itemB) => itemA.time.localeCompare(itemB.time));

    return result;
  }

  public isMoveDisabledForTeacher(item: MonthlyCalendarItem): boolean {
    if (item.isLesson && item.type === LessonType.FIRST) {
      return true;
    }

    const date = DateTime.fromFormat(item.date, 'yyyy-MM-dd').plus({ days: 2 }).toSQLDate();
    return isPastTime(date, item.time);
  }

  public isMoveDisabledForClient(item: MonthlyCalendarItem): boolean {
    if (item.isLesson && item.type === LessonType.FIRST) {
      return true;
    }

    const [lessonStart] = item.time.split(' - ');
    const lessonTime = DateTime.fromSQL(`${item.date} ${lessonStart}`);
    const now = DateTime.now();

    return now.plus({ hour: 3 }) >= lessonTime;
  }
}
